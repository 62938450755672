window.configCrypt =
    {
        VIDI_TOKEN_ADDRESS: "0xb256334d8c3d74d38fd0263e3da4f79f1e6ccd51",
        LOCKER_ADDRESS: "0xc3da15f9a1deb1558d08588c1a029ed782c0bfae",
        PRESALE_ADDRESS: "0xa4856409c94813253A909Ac99D0498595208295A",
        USDT_ADDRESS: "0x55d398326f99059ff775485246999027b3197955",
        RPC_NODES: [
            "https://bsc-dataseed.binance.org/",
            "https://bsc-dataseed4.binance.org/",
            "https://bsc-dataseed1.binance.org/",
            "https://bsc-dataseed3.binance.org/",
            "https://bsc-dataseed2.binance.org/"],
        WITHDRAW_GAS_VALUE: "300000",
        BUY_LOCATION_GAS_VALUE: "500000",
        CHAIN_ID: 56,
        BLOCKS_TO_READ: 4500,
        SEED_PRICE_USDT: "20000000000000000",
        PRIVATE_PRICE_USDT: "10000000000000000",
        SEED_AMOUNT: 12e5,
        PRIVATE_AMOUNT: 12500,
        PROJECT_ID: "98fd13da2d6c2f709c8c0c055b915d9f"
    };